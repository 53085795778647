import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import BusinessIcon from '@mui/icons-material/Business';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import { Feed } from '@mui/icons-material';

export const data = [
  {
    section: "Mon espace",
    content: [
      {
        title: "Mon profil",
        icon: <PersonIcon />,
        link: "/my-profile",
      },
      {
        title: "Tableau de bord",
        icon: <DashboardIcon />,
        link: "/",
      },
      {
        title: "Mes documents",
        icon: <FolderIcon />,
        link: "/my-documents",
      },
    ],
  },
  {
    section: "Blog",
    content: [
      {
        title: "Articles",
        icon: <Feed />,
        link: "/articles",
      },
    ],
  },
  {
    section: "Ressources humaines",
    content: [
      {
        title: "Mes collaborateurs",
        icon: <PeopleAltIcon />,
        link: "/my-employees",
      },
      {
        title: "Mes congés",
        icon: <BeachAccessIcon />,
        link: "/my-leave",
      },
    ],
  },
  {
    section: "Mes projets",
    content: [
      {
        title: "Mes projets",
        icon: <BusinessIcon />,
        link: "/projects",
      }
    ]
  },
  {
    section: "Guides",
    content: [
      {
        title: "Documentation",
        icon: <InfoIcon />,
        link: "/admin/documentation",
      },
    ],
  },
];
