export const EndPoint = {
    // API URL
    API_URL: 'http://localhost:8000/api',
    // API Routes
    API_ROUTES: {
        // User
        USER: {
            LOGIN: '/login',
            REGISTER: '/register',
            PROFILE: '/profile',
            FORGOT_PASSWORD: '/forgot-password',
            RESET_PASSWORD: '/reset-password',
            GET_ALL: '/users',
            GET_ONE: '/user',
            CREATE: '/user',
            UPDATE: '/user',
            DELETE: '/user',
        },
        // Product
        PRODUCT: {
            LIST: '/product/list',
            ADD: '/product/add',
            UPDATE: '/product/update',
            DELETE: '/product/delete',
        },

        BLOG: {
            GET_ALL: '/blogs',
            GET_ONE: '/blog',
            CREATE: '/blog',
            UPDATE: '/blog',
            DELETE: '/blog',
        },
    },
};