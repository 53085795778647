import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <div className="flex items-center justify-center h-full bg-[#f7fafc]">
      <div className="container flex justify-center">
        <div className="text-center">
          <h1 className="text-6xl font-semibold text-gray-800">404</h1>
          <p className="text-gray-500 mb-6">
            Il semble que vous vous êtes égaré.
          </p>
          <p className="text-gray-500 mb-6">
            La page que vous recherchez n'est pas disponible !
          </p>
          <img src="/not-found.gif" alt="404" width={300} height={300} />
          <Link to="/" className="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100">
            Retour à l'accueil
          </Link>
        </div>
      </div>
    </div>
  );
}