import axios from 'axios';
import { log } from 'console';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from "react-toastify";
import { Type } from 'react-toastify/dist/utils';

const DropzoneComponent = (postId: any = null) => {
    const [myFiles, setMyFiles] = useState<any[]>([]);
    const onDrop = useCallback((acceptedFiles: any) => {
        console.log("POST ID", postId);
        console.log("MES FICHIERS UPLOAD", acceptedFiles);
        setMyFiles([...myFiles, ...acceptedFiles]);
        try {
            const data = new FormData();

            data.append('post_id', postId.postId);

            acceptedFiles.forEach((file: any) => {
                console.log('MONIMAAAAAGE', file);
                data.append('image[]', file);
            });
            //data.append('image', acceptedFiles[0]);

            axios.post('http://localhost:8000/api/blog/upload', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    'Content-Type': 'multipart/form-data',
                },
            }).then((res) => {
                console.log(res);
                toast.success('Images uploaded successfully');
            }).catch((err) => {
                console.log(err);
                toast.error('Error uploading images');
            });
        } catch (error) {
            console.log(error);
            toast.error('Error uploading images');
        }
    }, [myFiles]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <>
            <div {...getRootProps()} className='border-2 border-dashed border-black rounded p-5 m-5 text-center cursor-pointer'>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Déposez vos images ici</p> :
                        <p>Glissez-déposez vos images </p>
                }
            </div>
            <div>
                <h1>Images</h1>
                <div className='flex flex-wrap'>
                    {myFiles.map((file: any, index: number) => {
                        return (
                            <div key={index} className='w-1/4 p-2'>
                                {/* a cross to delete image */}
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setMyFiles(myFiles.filter((_, i) => i !== index));
                                    }}
                                    className='text-red-800' >
                                    X
                                </button>
                                <img src={URL.createObjectURL(file)} alt={file.name} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default DropzoneComponent;
