import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import { EndPoint } from "../../services/endpoint";
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import { toast } from "react-toastify";
import { ExpandMore } from "@mui/icons-material";
import DropzoneComponent from "../../components/DropZone";

export default function BlogManagementPage() {
    const [posts, setPosts] = useState<any[]>([]);
    const [selectedPosts, setSelectedPosts] = useState<number[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmDeletePostId, setConfirmDeletePostId] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [editablePost, setEditablePost] = useState({ id: null, title: "", description: "" });

    const openModal = (post: any = null) => {
        setEditablePost(post);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setEditablePost({ id: null, title: "", description: "" });
        setIsModalOpen(false);
    }

    const fetchPosts = async (page: number) => {
        try {
            const response = await axios.get(`${EndPoint.API_URL}${EndPoint.API_ROUTES.BLOG.GET_ALL}?page=${page}`);
            setPosts(response.data.data);
            setCurrentPage(response.data.current_page);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchPosts(currentPage);
    }, [currentPage]);

    const selectAll = () => {
        if (selectedPosts.length === posts.length) {
            setSelectedPosts([]);
        } else {
            setSelectedPosts(posts.map(post => post.id));
        }
    };

    const toggleUserSelection = (postId: number) => {
        if (selectedPosts.includes(postId)) {
            setSelectedPosts(selectedPosts.filter(id => id !== postId));
        } else {
            setSelectedPosts([...selectedPosts, postId]);
        }
    };

    const isAllSelected = posts.length > 0 && selectedPosts.length === posts.length;

    const requestDeleteConfirmation = (postId: number) => {
        setConfirmDeletePostId(postId);
    };

    const deletePost = async (postId: number) => {
        try {
            await axios.delete(`${EndPoint.API_URL}${EndPoint.API_ROUTES.BLOG.DELETE}/${postId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`
                }
            });
            setPosts(posts.filter(post => post.id !== postId));
            setConfirmDeletePostId(null);
            toast.success("L'article a été supprimé avec succès !");
        } catch (error) {
            console.error(error);
            toast.error("Une erreur s'est produite lors de la suppression de l'article !");
        }
    }

    const undoPost = (postId: number) => {
        setConfirmDeletePostId(null);
        toast.info("La suppression de l'article a été annulée !");
    };
    const savePost = async (event: any) => {
        const title = (document.getElementById("title") as HTMLInputElement).value;
        const description = (document.getElementById("description") as HTMLInputElement).value;
        event.preventDefault();
        try {
            if (editablePost.id == null) {
                const response = await axios.post(`${EndPoint.API_URL}${EndPoint.API_ROUTES.BLOG.CREATE}`, {
                    title: title,
                    description: description
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`
                    }
                });
                setPosts([...posts, response.data]);
            } else {
                const response = await axios.patch(`${EndPoint.API_URL}${EndPoint.API_ROUTES.BLOG.UPDATE}/${editablePost.id}`,
                    {
                        title: title,
                        description: description
                    }
                    , {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`
                        }
                    });
                setPosts(posts.map(post => post.id === response.data.id ? response.data : post));
            }
            closeModal();
            toast.success("L'article a été enregistré avec succès !");
        } catch (error) {
            console.error(error);
            toast.error("Une erreur s'est produite lors de l'enregistrement de l'article !");
        }
    };

    // const savePost = async (post: React.FormEvent<HTMLFormElement>) => {
    //     const title = (document.getElementById("title") as HTMLInputElement).value;
    //     const description = (document.getElementById("description") as HTMLInputElement).value;
    //     post.preventDefault();
    //     try {
    //         if (editablePost.id === 0) {
    //             const response = await axios.post(`${EndPoint.API_URL}${EndPoint.API_ROUTES.BLOG.CREATE}`, {
    //                 title,
    //                 description
    //             }, {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem("access_token")}`
    //                 }
    //             });
    //             setPosts([...posts, response.data]);
    //         } else {
    //             const response = await axios.patch(`${EndPoint.API_URL}${EndPoint.API_ROUTES.BLOG.UPDATE}/${editablePost.id}`, {
    //                 title,
    //                 description
    //             }, {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem("access_token")}`
    //                 }
    //             });
    //             setPosts(posts.map(post => post.id === response.data.id ? response.data : post));
    //             closeModal();
    //             toast.success("L'article a été enregistré avec succès !");
    //         }
    //     } catch (error) {
    //         console.error(error);
    //         toast.error("Une erreur s'est produite lors de l'enregistrement de l'article !");
    //     }
    // };

    return (
        <>
            <div className="container mx-auto py-8 w-full">
                <div className="mb-4 flex w-full flex-wrap items-center justify-between">
                    <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={openModal}>Ajouter une publication</button>
                    <h2 className="text-2xl font-semibold text-gray-800">
                        Publication
                    </h2>

                    <div className="flex space-x-3">
                        <input
                            type="text"
                            className="rounded-lg border border-gray-300 bg-white px-4 py-2 shadow-sm focus:border-purple-600 focus:ring-2 focus:ring-purple-600"
                            placeholder="Rechercher..."
                        />
                        <button
                            className="rounded-lg bg-gray-600 px-4 py-2 text-white shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                        >
                            Filtrer
                        </button>

                    </div>
                </div>
            </div>



            <div className="overflow-x-auto rounded-lg shadow">
                <table className="w-full leading-normal">
                    <thead>
                        <tr>
                            <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-left text-sm font-normal uppercase text-gray-800">
                                <input
                                    type="checkbox"
                                    onClick={selectAll}
                                    checked={isAllSelected}
                                    onChange={() => { }} // Pour éviter un warning sur la checkbox sans onChange
                                    className="rounded text-purple-600 focus:ring-purple-500"
                                />
                            </th>
                            <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-center text-sm font-normal uppercase text-gray-800">
                                ID</th>
                            <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-center text-sm font-normal uppercase text-gray-800">
                                Titre
                            </th>
                            <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-center text-sm font-normal uppercase text-gray-800">
                                Description
                            </th>
                            <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-center text-sm font-normal uppercase text-gray-800">
                                Date de création
                            </th>
                            <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-center text-sm font-normal uppercase text-gray-800">
                                Action
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {posts.map((post, index) => (
                            <tr key={post.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>

                                <td className="border-b border-gray-200 px-5 py-3 text-sm">
                                    <input
                                        type="checkbox"
                                        checked={selectedPosts.includes(post.id)}
                                        onChange={() => toggleUserSelection(post.id)}
                                        className="rounded text-purple-600 focus:ring-purple-500"
                                    />
                                </td>
                                <td className="border-b text-center border-gray-200 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {post.id}
                                </td>
                                <td className="border-b text-center border-gray-200 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {post.title}
                                </td>
                                <td className="border-b text-center border-gray-200 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {post.description}
                                </td>
                                <td className="border-b text-center border-gray-200 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    {post.created_at_formatted || "Pas de date de création"}
                                </td>
                                <td className="border-b text-center border-gray-200 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                    <span className={`mr-2 transition-opacity duration-300 text-red-800 font-bold ${confirmDeletePostId === post.id ? 'opacity-100' : 'opacity-0'}`}>
                                        Êtes vous sûr ?
                                    </span>
                                    {confirmDeletePostId === post.id ? (
                                        <>
                                            <button className="text-green-600 hover:text-green-900" onClick={() => deletePost(post.id)}>
                                                <CheckIcon></CheckIcon>
                                            </button>
                                            <button className="text-green-600 hover:text-green-900" onClick={() => undoPost(post.id)}>
                                                <UndoIcon></UndoIcon>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button className="text-blue-600 hover:text-blue-900" onClick={() => openModal(post)}>
                                                <EditIcon />
                                            </button>
                                            <button className="text-red-600 hover:text-red-900" onClick={() => requestDeleteConfirmation(post.id)}>
                                                <DeleteIcon />
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={6} className="bg-white border-t border-gray-200">
                                <div className="flex justify-center items-center py-4">
                                    <button
                                        type="button"
                                        className="mr-2 p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300"
                                        onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                                    >
                                        {/* Icône SVG pour flèche gauche */}
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                    <span className="text-sm font-medium text-gray-700">Page {currentPage} sur {totalPages}</span>
                                    <button
                                        type="button"
                                        className="ml-2 p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300"
                                        onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}
                                    >
                                        {/* Icône SVG pour flèche droite */}
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </button>

                                </div>
                            </td>
                        </tr>
                    </tfoot>

                </table>
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-screen z-50" id="my-modal">
                    <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
                        <div className="absolute top-0 right-0 pt-4 pr-4">
                            <button
                                onClick={closeModal}
                                className="text-gray-400 hover:text-gray-500"
                                aria-label="Fermer"
                            >
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="mt-3 text-center">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Enregistrer un article</h3>
                            <div className="mt-2 px-7 py-3">
                                <form className="w-full mx-auto max-w-3xl bg-white p-5 rounded-lg shadow-md dropzone" onSubmit={savePost}>
                                    {/* <!-- Personal InhtmlFormation --> */}
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="title">Titre</label>
                                        <div className="relative">
                                            <select className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                                id="title"
                                                value={editablePost.title}
                                                onChange={(e) => setEditablePost({ ...editablePost, title: e.target.value })}>

                                                <option value="Étude">Étude</option>
                                                <option value="Supervision">Supervision</option>
                                                <option value="Mise en service">Mise en service</option>
                                                <option value="Gestion de projet">Gestion de projet</option>
                                                <option value="Exploitation">Exploitation</option>
                                                <option value="Maintenance">Maintenance</option>
                                                <option value="Location équipement">Location équipement</option>
                                            </select>

                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <ExpandMore className="w-4 h-4" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="description">Description</label>
                                        <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="description" type="text" placeholder="Dupont" value={editablePost.description} onChange={(e) => setEditablePost({ ...editablePost, description: e.target.value })} />
                                    </div>


                                    {editablePost.id != null && (
                                        <>
                                            <p>{editablePost.id} </p>
                                            <div className="mb-6">
                                                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="pictures">Images</label>
                                            </div>
                                            <DropzoneComponent postId={editablePost.id} />
                                        </>
                                    )}


                                    <button type="submit" className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline">Enregistrer</button>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

}
