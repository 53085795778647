import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import { EndPoint } from "../../services/endpoint";
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import { toast } from "react-toastify";

export default function MyEmployeesPage() {
  const [usersData, setUsersData] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]); // Specify the type of selectedUsers as an array of numbers
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmDeleteUserId, setConfirmDeleteUserId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [editableUser, setEditableUser] = useState({
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    password: '',
    role: 'user', // Valeur par défaut
    //status: 'active', // Valeur par défaut
    avatar: '',
  });


  const openModal = (user: any = null) => {
    setEditableUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setEditableUser({
      id: null,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      password: '',
      role: 'user', // Valeur par défaut
      //status: 'active', // Valeur par défaut
      avatar: '',
    });
    setIsModalOpen(false);
  }
  const fetchUsers = async (page: number) => {
    try {
      const response = await axios.get(`${EndPoint.API_URL}${EndPoint.API_ROUTES.USER.GET_ALL}?page=${page}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });

      setUsersData(response.data.data);
      setTotalPages(response.data.last_page);
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUsers(1);
  }, []);

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const selectAll = () => {
    if (selectedUsers.length === usersData.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(usersData.map(user => user.id)); // Select all users by setting the selectedUsers state to an array of all user ids
    }
  };

  const toggleUserSelection = (userId: number) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]); // Add the userId to the selectedUsers state
    }
  };

  const isAllSelected = usersData.length > 0 && selectedUsers.length === usersData.length;


  const requestDeleteConfirmation = (userId: number) => {
    setConfirmDeleteUserId(userId);
  };

  const deleteUser = async (userId: number) => {
    console.log('Delete user with id:', userId);
    try {
      await axios.delete(`${EndPoint.API_URL}${EndPoint.API_ROUTES.USER.DELETE}/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      });
      // Mise à jour de la liste des utilisateurs après suppression
      setUsersData(usersData.filter(user => user.id !== userId));
      setConfirmDeleteUserId(null); // Réinitialisation de la demande de confirmation
      toast.success("Utilisateur supprimé avec succès");
    } catch (error: any) {
      console.error(error);
      if (error.response.data.message !== undefined)
        toast.error(`Erreur : ${error.response.data.message}`);
      else
        toast.error("Erreur lors de la suppression de l'utilisateur");
    }
  };

  const undoUser = (userId: number) => {
    setConfirmDeleteUserId(null);
    toast.info("Suppression annulée");
  };

  const saveUser = async (e: any) => {
    const firstName = (document.getElementById('first-name') as HTMLInputElement).value;
    const lastName = (document.getElementById('last-name') as HTMLInputElement).value;
    const email = (document.getElementById('email') as HTMLInputElement).value;
    const phone = (document.getElementById('phone') as HTMLInputElement).value;
    const street = (document.getElementById('street') as HTMLInputElement).value;
    const city = (document.getElementById('city') as HTMLInputElement).value;
    const state = (document.getElementById('state') as HTMLInputElement).value;
    const zip = (document.getElementById('zip') as HTMLInputElement).value;
    const country = (document.getElementById('country') as HTMLInputElement).value;
    const password = (document.getElementById('password') as HTMLInputElement).value || '';
    const role = (document.getElementById('role') as HTMLSelectElement).value;
    //const status = (document.getElementById('status') as HTMLSelectElement).value;
    const avatar = (document.getElementById('avatar') as HTMLInputElement).value;
    e.preventDefault();
    try {
      if (editableUser.id == null) {
        const response = await axios.post(`${EndPoint.API_URL}${EndPoint.API_ROUTES.USER.CREATE}`, {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          street: street,
          city: city,
          state: state,
          zip: zip,
          country: country,
          password: password,
          role: role,
          //status: status,
          avatar: avatar
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        });
        setUsersData([...usersData, response.data]);

      } else {
        const response = await axios.patch(`${EndPoint.API_URL}${EndPoint.API_ROUTES.USER.UPDATE}/${editableUser.id}`, {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          street: street,
          city: city,
          state: state,
          zip: zip,
          country: country,
          password: password,
          role: role,
          //status: status,
          avatar: avatar
        }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        });
        setUsersData(usersData.map(user => user.id === response.data.id ? response.data : user));

      };
      closeModal();
      toast.success("Utilisateur enregistré avec succès");
    } catch (error) {
      console.error(error);
      console.log(error);
      toast.error("Erreur lors de l'enregistrement de l'utilisateur");
    }
  };


  return (
    // searchbar <>
    <>
      <div className="container mx-auto py-8 w-full">
        <div className="mb-4 flex w-full flex-wrap items-center justify-between">
          <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={openModal}>Ajouter un collaborateur</button>
          <h2 className="text-2xl font-semibold text-gray-800">
            Employés
          </h2>

          <div className="flex space-x-3">
            <input
              type="text"
              className="rounded-lg border border-gray-300 bg-white px-4 py-2 shadow-sm focus:border-purple-600 focus:ring-2 focus:ring-purple-600"
              placeholder="Rechercher..."
            />
            <button
              className="rounded-lg bg-gray-600 px-4 py-2 text-white shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
            >
              Filtrer
            </button>

          </div>
        </div>
      </div>



      <div className="overflow-x-auto rounded-lg shadow">
        <table className="w-full leading-normal">
          <thead>
            <tr>
              <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-left text-sm font-normal uppercase text-gray-800">
                <input
                  type="checkbox"
                  onClick={selectAll}
                  checked={isAllSelected}
                  onChange={() => { }} // Pour éviter un warning sur la checkbox sans onChange
                  className="rounded text-purple-600 focus:ring-purple-500"
                />
              </th>
              <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-center text-sm font-normal uppercase text-gray-800">
                ID</th>
              <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-center text-sm font-normal uppercase text-gray-800">
                Nom Prénom
              </th>
              <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-center text-sm font-normal uppercase text-gray-800">
                Email
              </th>
              <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-center text-sm font-normal uppercase text-gray-800">
                Date de création
              </th>
              <th className="border-b border-gray-200 bg-gray-50 px-5 py-3 text-center text-sm font-normal uppercase text-gray-800">
                Action
              </th>

            </tr>
          </thead>
          <tbody>
            {usersData.map((user, index) => (
              <tr key={user.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>

                <td className="border-b border-gray-200 px-5 py-3 text-sm">
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => toggleUserSelection(user.id)}
                    className="rounded text-purple-600 focus:ring-purple-500"
                  />
                </td>
                <td className="border-b text-center border-gray-200 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {user.id}
                </td>
                <td className="border-b text-center border-gray-200 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {user.last_name} {user.first_name}
                </td>
                <td className="border-b text-center border-gray-200 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {user.email}
                </td>
                <td className="border-b text-center border-gray-200 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {user.created_at_formatted || "Pas de date de création"}
                </td>
                <td className="border-b text-center border-gray-200 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  <span className={`mr-2 transition-opacity duration-300 text-red-800 font-bold ${confirmDeleteUserId === user.id ? 'opacity-100' : 'opacity-0'}`}>
                    Êtes vous sûr ?
                  </span>
                  {confirmDeleteUserId === user.id ? (
                    <>
                      <button className="text-green-600 hover:text-green-900" onClick={() => deleteUser(user.id)}>
                        <CheckIcon></CheckIcon>
                      </button>
                      <button className="text-green-600 hover:text-green-900" onClick={() => undoUser(user.id)}>
                        <UndoIcon></UndoIcon>
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="text-blue-600 hover:text-blue-900" onClick={() => openModal(user)}>
                        <EditIcon />
                      </button>
                      <button className="text-red-600 hover:text-red-900" onClick={() => requestDeleteConfirmation(user.id)}>
                        <DeleteIcon />
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={6} className="bg-white border-t border-gray-200">
                <div className="flex justify-center items-center py-4">
                  <button
                    type="button"
                    className="mr-2 p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300"
                    onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                  >
                    {/* Icône SVG pour flèche gauche */}
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                  <span className="text-sm font-medium text-gray-700">Page {currentPage} sur {totalPages}</span>
                  <button
                    type="button"
                    className="ml-2 p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300"
                    onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}
                  >
                    {/* Icône SVG pour flèche droite */}
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>

                </div>
              </td>
            </tr>
          </tfoot>

        </table>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-screen z-50" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
            <div className="absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={closeModal}
                className="text-gray-400 hover:text-gray-500"
                aria-label="Fermer"
              >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Enregistrer un Utilisateur</h3>
              <div className="mt-2 px-7 py-3">
                <form className="w-full mx-auto max-w-3xl bg-white p-5 rounded-lg shadow-md" onSubmit={saveUser}>
                  {/* <!-- Personal InhtmlFormation --> */}
                  <div className="mb-6">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="first-name">Prénom</label>
                    <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="first-name" type="text" placeholder="Jean" value={editableUser.first_name} onChange={(e) => setEditableUser({ ...editableUser, first_name: e.target.value })} />
                  </div>
                  <div className="mb-6">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="last-name">Nom</label>
                    <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="last-name" type="text" placeholder="Dupont" value={editableUser.last_name} onChange={(e) => setEditableUser({ ...editableUser, last_name: e.target.value })} />
                  </div>
                  <div className="mb-6">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="email">Email</label>
                    <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="jean.dupont@example.com" value={editableUser.email} onChange={(e) => setEditableUser({ ...editableUser, email: e.target.value })} />
                  </div>
                  <div className="mb-6">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="phone">Téléphone</label>
                    <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="+33 6 12 34 56 78" value={editableUser.phone} onChange={(e) => setEditableUser({ ...editableUser, phone: e.target.value })} />
                  </div>

                  {/* <!-- Address InhtmlFormation --> */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                    <div>
                      <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="street">Rue</label>
                      <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="street" type="text" placeholder="1234 Rue Principale" value={editableUser.street} onChange={(e) => setEditableUser({ ...editableUser, street: e.target.value })} />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="city">Ville</label>
                      <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="city" type="text" placeholder="Ville" value={editableUser.city}
                        onChange={(e) => setEditableUser({ ...editableUser, city: e.target.value })}
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="state">État/Province</label>
                      <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="state" type="text" placeholder="État" value={editableUser.state}
                        onChange={(e) => setEditableUser({ ...editableUser, state: e.target.value })} />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="zip">Code Postal</label>
                      <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="zip" type="text" placeholder="Code Postal" value={editableUser.zip} onChange={(e) => setEditableUser({ ...editableUser, zip: e.target.value })} />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="country">Pays</label>
                      <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="country" type="text" placeholder="Pays" value={editableUser.country} onChange={(e) => setEditableUser({ ...editableUser, country: e.target.value })} />
                    </div>
                  </div>

                  {/* <!-- Account InhtmlFormation --> */}

                  <div className="mb-6">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="password">Mot de passe</label>
                    <input
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="password"
                      type="password"
                      placeholder="••••••••"
                    />
                  </div>


                  <div className="mb-6">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="role">Rôle</label>
                    <select className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="role" value={editableUser.role} onChange={(e) => setEditableUser({ ...editableUser, role: e.target.value })}>
                      <option value="user">Utilisateur</option>
                      <option value="admin">Administrateur</option>
                    </select>
                  </div>


                  <div className="mb-6">
                    <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="avatar">Avatar (URL)</label>
                    <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="avatar" type="text" placeholder="URL de l'avatar" value={editableUser.avatar} onChange={(e) => setEditableUser({ ...editableUser, avatar: e.target.value })} />
                  </div>

                  <button type="submit" className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline">Enregistrer</button>
                </form>


              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
}

