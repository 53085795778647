import BeachAccessIcon from '@mui/icons-material/BeachAccess'; // Pour "Congé annuel"
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Pour "RTT"
import RestoreIcon from '@mui/icons-material/Restore'; // Pour "Récup"
import { SetStateAction, useState } from 'react';

export default function MyLeavePage() {
  const [activeTab, setActiveTab] = useState('Information');

  const tabs = [
    { name: 'Information', disabled: false },
    { name: 'Voir mes congés', disabled: false },
    { name: 'Gestion des congés', disabled: false },
  ];

  return (
    <div className="border border-gray-200  p-5 text-gray-800">
      <h1 className='text-center text-3xl m-5'>Mes congés</h1>
      <div className="bg-gray-50">
        <ul className="flex space-x-2  bg-gray-50">
          {tabs.map((tab) => (
            <li
              key={tab.name}
              className={`cursor-pointer px-4 py-2 rounded-t ${activeTab === tab.name
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-800 bg-gray-50 hover:bg-gray-600 hover:text-white'
                } ${tab.disabled && 'opacity-50 cursor-not-allowed'}`}
              onClick={() => !tab.disabled && setActiveTab(tab.name)}
            >
              {tab.name}
            </li>
          ))}
        </ul>
        <div className="mt-5 text-gray-800  bg-gray-50">
          {activeTab === 'Information' &&
            (
              <div>

                <section className="text-gray-600 body-font">
                  <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-20">
                      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Vos congés restants</h1>
                    </div>
                    <div className="flex flex-wrap -m-4 text-center justify-center">
                      {/* Congé annuel */}
                      <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                          <BeachAccessIcon className="text-indigo-500 w-12 h-12 mb-3 inline-block" />
                          <h2 className="title-font font-medium text-3xl text-gray-900">XX jours</h2>
                          <p className="leading-relaxed">Congé annuel</p>
                        </div>
                      </div>
                      {/* RTT */}
                      <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                          <AccessTimeIcon className="text-indigo-500 w-12 h-12 mb-3 inline-block" />
                          <h2 className="title-font font-medium text-3xl text-gray-900">XX jours</h2>
                          <p className="leading-relaxed">RTT</p>
                        </div>
                      </div>
                      {/* Récup */}
                      <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                          <RestoreIcon className="text-indigo-500 w-12 h-12 mb-3 inline-block" />
                          <h2 className="title-font font-medium text-3xl text-gray-900">XX heures</h2>
                          <p className="leading-relaxed">Récup</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )
          }
          {activeTab === 'Voir mes congés' && <div>Dashboard content</div>}
          {activeTab === 'Gestion des congés' && <div>Settings content</div>}
        </div>
      </div>
    </div>
  );
}
