import axios from "axios";
import { useEffect, useState } from "react";
import { EndPoint } from "../../services/endpoint";

export default function MyProfilePage() {
    const [editableUser, setEditableUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        role: 'user',
        avatar: ''
    });

    const saveUser = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(editableUser);
    }

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${EndPoint.API_URL}${EndPoint.API_ROUTES.USER.PROFILE}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`
                    }
                });
                setEditableUser(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        fetchUserProfile();
    }, []);

    return (
        <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">

            <div className="mt-3 text-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Mon profil</h3>
                <div className="mt-2 px-7 py-3">
                    <form className="w-full mx-auto max-w-3xl  p-5 rounded-lg shadow-md" onSubmit={saveUser}>
                        {/* <!-- Personal InhtmlFormation --> */}
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="first-name">Prénom</label>
                            <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="first-name" type="text" placeholder="Jean" value={editableUser.first_name} onChange={(e) => setEditableUser({ ...editableUser, first_name: e.target.value })} />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="last-name">Nom</label>
                            <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="last-name" type="text" placeholder="Dupont" value={editableUser.last_name} onChange={(e) => setEditableUser({ ...editableUser, last_name: e.target.value })} />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="email">Email</label>
                            <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="jean.dupont@example.com" value={editableUser.email} onChange={(e) => setEditableUser({ ...editableUser, email: e.target.value })} />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="phone">Téléphone</label>
                            <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="+33 6 12 34 56 78" value={editableUser.phone} onChange={(e) => setEditableUser({ ...editableUser, phone: e.target.value })} />
                        </div>

                        {/* <!-- Address InhtmlFormation --> */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                            <div>
                                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="street">Rue</label>
                                <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="street" type="text" placeholder="1234 Rue Principale" value={editableUser.street} onChange={(e) => setEditableUser({ ...editableUser, street: e.target.value })} />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="city">Ville</label>
                                <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="city" type="text" placeholder="Ville" value={editableUser.city}
                                    onChange={(e) => setEditableUser({ ...editableUser, city: e.target.value })}
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="state">État/Province</label>
                                <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="state" type="text" placeholder="État" value={editableUser.state}
                                    onChange={(e) => setEditableUser({ ...editableUser, state: e.target.value })} />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="zip">Code Postal</label>
                                <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="zip" type="text" placeholder="Code Postal" value={editableUser.zip} onChange={(e) => setEditableUser({ ...editableUser, zip: e.target.value })} />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="country">Pays</label>
                                <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="country" type="text" placeholder="Pays" value={editableUser.country} onChange={(e) => setEditableUser({ ...editableUser, country: e.target.value })} />
                            </div>
                        </div>

                        {/* <!-- Account InhtmlFormation --> */}

                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="password">Mot de passe</label>
                            <input
                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                id="password"
                                type="password"
                                placeholder="••••••••"
                            />
                        </div>


                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="role">Rôle</label>
                            <select className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="role" value={editableUser.role} onChange={(e) => setEditableUser({ ...editableUser, role: e.target.value })}>
                                <option value="user">Utilisateur</option>
                                <option value="admin">Administrateur</option>
                            </select>
                        </div>


                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="avatar">Avatar (URL)</label>
                            <input className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" id="avatar" type="text" placeholder="URL de l'avatar" value={editableUser.avatar} onChange={(e) => setEditableUser({ ...editableUser, avatar: e.target.value })} />
                        </div>

                        <button type="submit" className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline">Enregistrer</button>
                    </form>


                </div>
            </div>
        </div>
    );
}