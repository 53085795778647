import { Route, HashRouter as Router, Routes } from 'react-router-dom'; // Import the 'Router' component from 'react-router-dom'
import './App.css';
import { DashboardLayout } from './dashboard/Layout';
import HomePage from './pages';
import MyDocumentsPage from './pages/admin/my-documents';
import MyEmployeesPage from './pages/admin/my-employees';

import { Navigate } from 'react-router-dom';
import NotFoundPage from './pages/admin/not-found';
import LoginPage from './pages/admin/login';
import MyLeavePage from './pages/admin/my-leave';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // N'oublie pas d'importer le CSS
import ForgotPassword from './pages/admin/forgot-password';
import { useState } from 'react';
import MyProfilePage from './pages/admin/my-profile';
import PostsPage from './pages/admin/posts';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const isAuthenticated = () => {
    // Ici, tu vérifies l'état de connexion de l'utilisateur
    return localStorage.getItem("access_token") !== null && localStorage.getItem("access_token") !== undefined && localStorage.getItem("access_token") !== "";
  };
  //return <>{children}</>;
  return isAuthenticated() ? <>{children}</> : <Navigate to="/login" />;
};


function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <>
      <Router>
        <DashboardLayout>
          <Routes>
            {/* //si je ne suis pas sur une route, je veux afficher la page d'accueil */}
            <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} />
            <Route path="/login" element={<LoginPage />} />
            <Route path='/my-profile' element={<PrivateRoute><MyProfilePage /></PrivateRoute>} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/articles" element={<PrivateRoute><PostsPage /></PrivateRoute>} />
            <Route path="/my-documents" element={<PrivateRoute><MyDocumentsPage /></PrivateRoute>} />
            <Route path="/my-employees" element={<PrivateRoute><MyEmployeesPage /></PrivateRoute>} />
            <Route path="/my-leave" element={<PrivateRoute><MyLeavePage /></PrivateRoute>} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </DashboardLayout>
      </Router>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

    </>
  );
}

export default App;
