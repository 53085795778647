interface ContentProps {
  title: string;
}

export function Content(props: ContentProps) {
  return (
    <div>
      <h1>{props.title}</h1>
      <p>Content goes here</p>
    </div>
  );
}
