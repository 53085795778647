import axios from "axios";
import { useDashboardContext } from "./Provider";
import { EndPoint } from "../services/endpoint";
import { useEffect, useState } from "react";
import { User } from "../models/users";
import { TinyLoading } from "../components/tinyLoading";

export function TopBar() {
  const { openSidebar } = useDashboardContext();

  const [userProfile, setUserProfile] = useState<any>(null);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      //if (!localStorage.getItem('access_token')) return;
      //if (!isAuthenticated()) return;
      try {
        const response = await axios.get(`${EndPoint.API_URL}${EndPoint.API_ROUTES.USER.PROFILE}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        });
        if (response.status == 200) {
          setUserProfile(response.data);
        } else {
          setUserProfile(null);
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchUserProfile();
  }, []);

  const isAuthenticated = async () => {
    return localStorage.getItem("userToken");
  }

  const logout = () => {
    localStorage.removeItem("access_token");
    window.location.href = "/login";
  }

  const toggleSubMenu = () => {
    setIsSubMenuVisible(!isSubMenuVisible);
  };


  return (
    <header className="relative z-10 h-16 w-full items-center md:h-20">
      <div className="relative mx-auto flex h-full flex-col justify-center px-3">
        <div className="relative flex w-full items-center pl-1 sm:ml-0 sm:pr-2 ">
          <div className="container relative left-0 flex h-auto  w-3/4">
            <div className="group relative flex h-full w-12 items-center ">
              <button
                type="button"
                aria-expanded="false"
                aria-label="Toggle sidenav"
                onClick={openSidebar}
                className="text-4xl text-gray-700 focus:outline-none lg:hidden"
              >
                &#8801;
              </button>
            </div>
            {/* <div className="group relative hidden h-full w-full items-center md:flex lg:w-64">
              <div className="absolute flex h-10 w-auto cursor-pointer items-center justify-center p-3 pr-2 text-sm uppercase text-gray-500 sm:hidden">
                <svg
                  fill="none"
                  className="relative h-5 w-5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <svg
                fill="black"
                className="pointer-events-none absolute left-0 ml-4 hidden h-4 w-4  group-hover:text-gray-400 sm:block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
              </svg>
              <input
                type="text"
                className="block w-full rounded-2xl bg-gray-100 py-1.5 pl-10 pr-4 leading-normal text-gray-400 opacity-90 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Search"
              />
              <div className="absolute right-0 mr-2 hidden h-auto rounded-2xl border border-gray-300 px-2 py-1 text-xs text-gray-400 md:block">
                +
              </div>
            </div> */}
          </div>
          <div className="relative ml-5 flex w-9/12 items-center justify-end p-1 sm:right-auto sm:mr-0 md:w-1/4">

            {/* <a className="block pr-1 text-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z" />
              </svg>
            </a>
            <a
              href="#"
              className="relative block rounded px-3 py-2 text-gray-700"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                </svg>
              </span>
              <span className="absolute right-0 top-0 rounded-full bg-blue-700 px-2 py-0.5 text-xs font-bold text-white">
                3
              </span>
            </a> */}
            <a href="#" className="relative block pl-5" onClick={(e) => { e.preventDefault(); toggleSubMenu(); }}>
              {
                userProfile !== null ? (
                  <>
                    <img
                      alt={`image`}
                      src={'http://localhost:8000/images/logo.png'}
                      className="mx-auto h-10 w-10 rounded-full object-cover"
                    />
                    <span>
                      {userProfile.first_name + " " + userProfile.last_name}
                    </span>
                    {isSubMenuVisible && (
                      <div className="absolute left-0 mt-2 py-2 w-auto bg-white rounded-md shadow-xl">
                        {/* Contenu du sous-menu ici */}
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Voir le profil</a>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Changer de photo de profil</a>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Modifier le mot de passe</a>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={logout}>Se deconnecter</a>
                        {/* Ajoute d'autres options ici */}
                      </div>
                    )}
                  </>
                ) : (
                  <TinyLoading />
                )
              }
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
