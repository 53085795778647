import axios from "axios";
import { useEffect, useState } from "react";
import { EndPoint } from "../../services/endpoint";
import { Link, Route } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

export default function LoginPage() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Vérifier si les champs sont remplis
  const isReady = email.length > 0 && password.length > 0;

  useEffect(() => {
    // Vérifier si l'utilisateur est déjà connecté
    if (localStorage.getItem('access_token') !== null) {
      window.location.href = '/';
    }
  }, []);

  const handleLogin = async () => {
    if (!isReady) return;

    // Envoi des données au serveur
    try {
      const response = await axios.post(`${EndPoint.API_URL}${EndPoint.API_ROUTES.USER.LOGIN}`, {
        email: email,
        password: password
      });
      if (response.status === 200) {
        // Enregistrement du token dans le localStorage
        localStorage.setItem('access_token', response.data.access_token);
        // Enregistrement des données de l'utilisateur dans le localStorage
        localStorage.setItem('user', JSON.stringify(response.data.user));

        document.cookie = `auth_token=${response.data.access_token}; path=/; expires=${new Date(response.data.expires_at)}; secure; HttpOnly`;


        // Redirection vers la page d'accueil
        window.location.href = '/';
      }
    } catch (error) {
      console.error(error);
      toast.error(`Erreur de connexion 😒`);
    }
  }

  return (
    // <!-- component -->
    <div className="min-h-full  py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
        </div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
              <img src="/logo.png" className="h-10 m-5 w-auto mx-auto" />
              <h1 className="text-2xl font-semibold">Veuillez vous connecter</h1>
            </div>
            <div className="divide-y divide-gray-200">
              <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                <div className="relative">
                  <input autoComplete="off" id="email" name="email" type="text" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="email" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Email</label>
                </div>
                <div className="relative">
                  <input autoComplete="off" id="password" name="password" type="password" className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="password" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Mot de passe</label>
                </div>
                <div>
                  <Link to="/forgot-password" className="text-xs text-blue-500">Mot de passe oublié ?</Link>
                </div>
                <div className="relative w-full text-center">
                  <button
                    className={`rounded-md px-2 py-1 text-white ${isReady ? 'bg-blue-500' : 'bg-gray-500 cursor-not-allowed'}`}
                    onClick={handleLogin}
                    disabled={!isReady}
                    title={!isReady ? "Veuillez remplir tous les champs pour vous connecter" : ""}
                  >
                    Connexion
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}